import React from 'react';
import styled from 'styled-components';


// Importing Google Font
import { createGlobalStyle } from 'styled-components';
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
  body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #333;
    overflow-x: hidden;
    scroll-behavior: smooth; // Enables smooth scrolling
  }
  a {
    text-decoration: none; // Removes underline from all links
    color: white; // Sets default link color to white for better visibility on dark backgrounds
  }
  p {
    max-width: 640px;
    margin: 0 auto;
    line-height: 1.5em;
  }
  h3 {
  }
`;


const HeaderWrapper = styled.header`
  position: sticky; // Make header sticky
  top: 0; // Stick to the top of the viewport
  z-index: 1000; // Ensure it's above other content
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  background-color: #333;
  color: #fff;
  font-weight: 700;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); // Optional: adds shadow for depth

  a {
    position: relative;
    overflow: hidden;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: #fff;
      transition: width 0.3s ease-in-out;
    }
    &:hover:before {
      width: 100%;
    }
  }
`;

const Brand = styled.h1`
  margin: 0;
  font-size: 2em; 
`;


const SectionWrapper = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.bgColor || '#fff'};
  color: ${props => props.textColor || '#333'};
  position: relative; // Add this line
  animation: Gradient 15s ease infinite;

  @keyframes Gradient {
    0% { background-position: 0% 50% }
    50% { background-position: 100% 50% }
    100% { background-position: 0% 50% }
  }
`;


const ContentWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  padding: 2em;
  text-align: center;
  @media (max-width: 768px) {
    padding: 1em;
  }
`;

const HeroText = styled.h1`
  font-size: 5em;
  font-weight: 700;
  margin-bottom: 0.5em;
  animation: fadeInUp 1s ease-out;
  @media (max-width: 768px) {
    font-size: 3em;
  }

  @keyframes fadeInUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

// Header Component
const Header = () => {
  // Handler for clicking navigation links
  const handleNavLinkClick = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    const href = e.target.getAttribute('href'); // Get the href attribute of the clicked link
    const offsetTop = document.querySelector(href).offsetTop; // Get the top position of the target section

    window.scrollTo({
      top: offsetTop - 60, // Subtract header height to avoid overlap
      behavior: 'smooth' // Smooth scrolling
    });
  };

  return (
    <HeaderWrapper>
      <Brand>Recurre</Brand>
      <nav>
        <a href="#hero" onClick={handleNavLinkClick} style={{ margin: '0 10px' }}>Home</a>
        <a href="#about" onClick={handleNavLinkClick} style={{ margin: '0 10px' }}>About</a>
        <a href="#values" onClick={handleNavLinkClick} style={{ margin: '0 10px' }}>Values</a>
        <a href="#contact" onClick={handleNavLinkClick} style={{ margin: '0 10px' }}>Contact</a>
      </nav>
    </HeaderWrapper>
  );
};



const BlurredEllipse = styled.div`
  position: absolute;
  width: 500px;
  height: 500px;
  background: radial-gradient(circle at center, rgba(0,123,255,0.5), transparent);
  filter: blur(100px);
  z-index: 1;
  animation: float 12s ease-in-out infinite;
  
  @keyframes float {
    0%, 100% {
      transform: translate(0, 0);
    }
    25% {
      transform: translate(100px, -200px);
    }
    50% {
      transform: translate(-200px, 200px);
    }
    75% {
      transform: translate(200px, -100px);
    }
  }
`;

// Hero Section Component
const Hero = () => {
  return (
    <SectionWrapper id="hero" bgColor="#f8f9fa" textColor="#333">
      <ContentWrapper>
        <HeroText>Empowering Change Through Learning</HeroText>
        <p style={{ fontSize: '1.5em' }}>Discover the transformative power of digital education tailored for real-life impact.</p>
      </ContentWrapper>
    </SectionWrapper>
  );
};


// About Section Component
const About = () => {
  return (
    <SectionWrapper id="about" bgColor="rgba(20,23,105,1)" textColor="#fff">
      <BlurredEllipse style={{ top: '50%', right: '30%' }} />

      <ContentWrapper>
        <AboutText>About Recurre</AboutText>
        <AboutParagraph>
          At Recurre, we develop digital solutions that enlighten and inspire, shaping better futures through learning and personal growth.
        </AboutParagraph>
      </ContentWrapper>
    </SectionWrapper>
  );
};

const AboutText = styled.h2`
  font-size: 2.5em;
  margin-bottom: 1em;
  color: #fff;
  clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);
  animation: revealText 1s forwards ease-in-out;

  @keyframes revealText {
    to {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
`;

const AboutParagraph = styled.p`
  font-size: 1.2em;
  color: #fff;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s 1s forwards ease-out; // Delays this animation to start after the header reveals

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;


const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 2em;
  margin: 1em;
  background: white;
  min-height: 200px;
  max-width: 200px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s ease-out forwards;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }

  &:hover h3 {
    color: #007BFF;
  }
`;
// New styled-component for a Grid container
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 0.25em;
  justify-items: center;
  align-items: start;
`;

// Values Section Component
const Values = () => {
  const cardTitles = [
    "Constantly pushing the boundaries to redefine digital education",
    "Empowering every user to reach their fullest potential",
    "Ensuring unparalleled excellence in every creation",
    "Focused on creating significant, positive changes in the lives of our users"
  ];

  return (
    <SectionWrapper id="values" bgColor="rgba(0,100,250,0.25)">
      <BlurredEllipse style={{ top: '70%', left: '40%' }} />

      <ContentWrapper>
        <h2 style={{ fontSize: '2.5em', marginBottom: '1em' }}>Our Values, In No Particular Order</h2>
        <Grid>
          {cardTitles.map((title, index) => (
            <Card style={{ animationDelay: `${index * 0.2}s` }} key={index}>
              <h3>{title}</h3>
            </Card>
          ))}
        </Grid>
      </ContentWrapper>
    </SectionWrapper>
  );
};


// Footer Component
const Footer = () => {
  return (
    <SectionWrapper id="contact" bgColor="#f8f9fa" textColor="#333">
      <ContentWrapper>
        <BlurredEllipse style={{ bottom: '10%', right: '20%' }} />

        <h2 style={{ fontSize: '2.5em', marginBottom: '1em' }}>Connect With Us</h2>
        <p style={{ fontSize: '1.2em' }}>
          We're eager to hear from you! Send us your feedback, questions, or a book that inspired you at info@recurre.net.
        </p>
      </ContentWrapper>
    </SectionWrapper>
  );
};


// Main App Component
const App = () => {
  return (
    <div className="App">
      <GlobalStyle />
      <BlurredEllipse style={{ top: '0%', left: '0%' }} />
      <BlurredEllipse style={{ top: '0%', right: '60%' }} />
      <BlurredEllipse style={{ top: '90%', left: '80%' }} />
      <BlurredEllipse style={{ top: '90%', right: '0%' }} />

      <Header />
      <Hero />
      <About />
      <Values />
      <Footer />
    </div>
  );
};

export default App;
